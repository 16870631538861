import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import React, { useEffect, useState } from 'react';
import { fetchRA_Error } from '@zert-packages/actions/api';
import { WithDemandsTabs } from './components/DemandsComponents';
import DescriptionField from './components/DescriptionField';
import MembersTable from './Members';
import LongSelectList from './components/LongSelectList';
import { loadTemplateNewRMP } from './redux/servicesMiddlewares';
import { initDemandProjectAPI } from './APIs';

const formattedMessages = {
  dialogTitle: <FormattedMessage id="RMP.NewRMP.CREATING" defaultMessage="New demand project" />,
  confirm: <FormattedMessage id="newproject.create" defaultMessage="Create" />,
  cancel: <FormattedMessage id="newpage.project.cancel" defaultMessage="Cancel" />
};

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: 20,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  fieldContainerWithLabel: {
    padding: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  generalSettingsContainer: {
    padding: '10px',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateRows: '35px 28px 40px 56px 54px 47px 1fr',
    gridGap: 10,
    gridTemplateColumns: '1fr'
  }
});

function CreateRMPDialog({ handleClose, onCreate }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const templateNewRMP = useSelector((state) => state.RMP.templateNewRMP);
  const [creating, setCreating] = useState(false);
  const catalog = useSelector((state) => state.catalog);
  const [state, setState] = useState({
    name: '',
    projectManager: null,
    subProjects: 1,
    rows: 1,
    description: '',
    clientDirectives: [],
    clientProvision: [],
    clientStandarts: [],
    clientOther: [],
    members: []
  });
  const [tabIndex, setTabIndex] = useState(0);

  const updateProjectManager = (user) => {
    if (user === null) {
      setState((prev) => ({
        ...prev,
        projectManager: null
      }));
      return;
    }

    setState((prev) => {
      const projectManager = {
        user,
        username: user.userName,
        function: 'PROJECTMANAGER',
        name: user.fullname,

        email: user.email,
        active: true
      };
      return {
        ...prev,
        projectManager,

        members: [...prev.members.filter((m) => projectManager.username !== m.username), projectManager]
      };
    });
  };

  const addMember = (member) =>
    setState((prev) => {
      if (prev.members.find((m) => m.email === member.email)) {
        return prev;
      }
      return {
        ...prev,
        members: [...prev.members, member]
      };
    });

  const removeMember = (userName) =>
    setState((prev) => {
      const sameAsProjectManager = userName === (prev.projectManager && prev.projectManager.username);
      return {
        ...prev,
        projectManager: sameAsProjectManager ? null : prev.projectManager,
        members: prev.members.filter((mem) => mem.username !== userName)
      };
    });
  const updateMember = (member) =>
    setState((prev) => {
      const sameAsProjectManager = member.username === (prev.projectManager && prev.projectManager.username);
      if (sameAsProjectManager) {
        updateProjectManager(member);
      }
      return {
        ...prev,
        members: prev.members.map((mem) => {
          if (mem.username !== member.username) return mem;
          return {
            ...member
          };
        })
      };
    });

  const updateStateDemands = (name) => (callback) =>
    setState((prev) => ({
      ...prev,
      [name]: callback(prev[name])
    }));

  const updateState = (name) => (event) => {
    const { value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!templateNewRMP) dispatch(loadTemplateNewRMP());
  }, []);
  if (!templateNewRMP) {
    return (
      <Loader>
        <FormattedMessage id="RMP.NewRMP.LoadingTemplate" defaultMessage="Template" />
      </Loader>
    );
  }

  async function handleCreate() {
    setCreating(true);
    const demands = [
      ...state.clientDirectives,
      ...state.clientOther,
      ...state.clientStandarts,
      ...state.clientProvision
    ];
    initDemandProjectAPI(state.subProjects, state.rows, {
      name: state.name,
      demands,
      members: state.members,
      roundRobin: state.projectManager,
      description: state.description
    })
      .then((result) => {
        setCreating(false);
        handleClose();
        onCreate({ versionId: result });
      })
      .catch((error) => {
        setCreating(false);
        handleClose();
        dispatch(fetchRA_Error(error));
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={handleClose}
      PaperProps={{ sx: { minHeight: '80%', maxHeight: '80%' } }}
    >
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {templateNewRMP === null || catalog === null || creating ? (
          <Loader />
        ) : (
          <>
            <WithDemandsTabs
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              yourTabs={[
                <FormattedMessage id="RMP.NewRMP.MainInfo" defaultMessage="Main" />,
                <FormattedMessage id="RMP.NewRMP.MembersTab" defaultMessage="Members" />
              ]}
            />
            {tabIndex === 0 && (
              <div className={cl.generalSettingsContainer}>
                <Typography color="textPrimary" variant="h6">
                  <FormattedMessage id="RMP.NewRMP.CreateProjectTitle" defaultMessage="Create your project" />
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  <FormattedMessage
                    id="RMP.NewRMP.SubTitle"
                    defaultMessage="This dialog will help you create your project matrix, specifying the size of it and your demands."
                  />
                </Typography>

                <TextField
                  variant="standard"
                  fullWidth
                  value={state.name}
                  onChange={updateState('name')}
                  label={<FormattedMessage id="RMP.NewRMP.Name" defaultMessage="Name" />}
                />
                <UserSuggestBox
                  onChange={updateProjectManager}
                  label={<FormattedMessage id="RMP.NewRMP.ProjectManager" defaultMessage="Project Manager" />}
                  getUserId={(user) => user.username}
                  getUserLabel={(user) => user.name}
                  getUsers={getUsersQuery}
                  symbolsToStart={3}
                  initialValue={state.projectManager ? state.projectManager.name : ''}
                />
                <TextField
                  sx={{
                    '.MuiInputLabel-root': { fontSize: '1.25rem' }
                  }}
                  variant="standard"
                  fullWidth
                  label={<FormattedMessage id="RMP.NewRMP.SubProjects" defaultMessage="Sub-projects" />}
                  type="number"
                  value={state.subProjects}
                  onChange={updateState('subProjects')}
                />
                <TextField
                  sx={{
                    '.MuiInputLabel-root': { fontSize: '1.25rem' }
                  }}
                  variant="standard"
                  fullWidth
                  label={<FormattedMessage id="RMP.NewRMP.Rows" defaultMessage="Rows" />}
                  type="number"
                  value={state.rows}
                  onChange={updateState('rows')}
                />
                <DescriptionField onChange={updateState('description')} value={state.description} />
              </div>
            )}
            {tabIndex === 1 && (
              <MembersTable
                members={state.members}
                addMember={addMember}
                removeMember={removeMember}
                updateMember={updateMember}
              />
            )}
            {tabIndex === 2 && (
              <LongSelectList
                setActiveItems={updateStateDemands('clientDirectives')}
                availableItems={templateNewRMP.clientDirectives}
                // 0  template={templateNewRMP}
                activeItems={state.clientDirectives}
              />
            )}
            {tabIndex === 3 && (
              <LongSelectList
                setActiveItems={updateStateDemands('clientProvision')}
                availableItems={templateNewRMP.clientProvision}
                // template={templateNewRMP}
                activeItems={state.clientProvision}
              />
            )}
            {tabIndex === 4 && (
              <LongSelectList
                setActiveItems={updateStateDemands('clientStandarts')}
                availableItems={templateNewRMP.clientStandarts}
                // template={templateNewRMP}
                activeItems={state.clientStandarts}
              />
            )}
            {tabIndex === 5 && (
              <LongSelectList
                setActiveItems={updateStateDemands('clientOther')}
                availableItems={templateNewRMP.clientOther}
                // template={templateNewRMP}
                activeItems={state.clientOther}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!(state.name.length > 0 && state.projectManager && +state.subProjects > 0 && +state.rows > 0)}
          onClick={handleCreate}
          color="primary"
          variant="outlined"
        >
          {formattedMessages.confirm}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {formattedMessages.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateRMPDialog;
