import React, { useState, Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import 'react-datepicker/dist/react-datepicker.css';
import { definePEDClass } from '@zert-packages/utils/definePEDClass';
import { initialPED } from './serviceMidleware';
import { initPEDAPI } from './API';

import PEDWrapper from './PEDWrapper';

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: 20,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
});
function CreateNewPEDDialog({ handleClose, onCreate }) {
  const cl = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [ped, setPed] = useState(initialPED);
  const [name, setName] = useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const handleOnCreate = () => {
    setIsLoading(true);

    initPEDAPI({ ...ped, classG: definePEDClass(ped), name, info: null })
      .then((res) => {
        onCreate(res);
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
        throw new Error(e);
      });
  };

  return (
    <Dialog fullWidth maxWidth="md" open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="ElementTile.Actions.AFS_CALC" defaultMessage="AFS 2017:3 Calculator" />
      </DialogTitle>

      <DialogContent className={cl.DialogContent}>
        <TextField
          id="standard-pressure"
          label={<FormattedMessage id="PEDView.Name" defaultMessage="Name" />}
          value={name}
          onChange={handleChange}
          fullWidth
          variant="outlined"
        />
        <PEDWrapper key="newped" object={{ ...initialPED, onChange: setPed }} />
      </DialogContent>

      <DialogActions>
        <>
          <Button onClick={handleOnCreate} color="primary" disabled={!(name && name.length > 0)} variant="outlined">
            <FormattedMessage id="RMP.CreateNewPEDDialog.Confirm" defaultMessage="Create" />
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="RMP.CreateNewPEDDialog.Cancel" defaultMessage="Cancel" />
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewPEDDialog;
