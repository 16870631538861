import React, { Fragment } from 'react';
import './style.css';
import ReactGA from 'react-ga4';
import ButtonRM from '../Button/index';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.onTabClick = this.onTabClick.bind(this);
    this.state = {
      activeItem: null,
      activeid: -1
    };
    //   var activeTab = this.props.itemTabs.filter((item) => item.styleName.includes("active"));
    // if (activeTab.length === 0 && this.props.itemTabs > 0) {
    //     this.props.itemTabs[0].styleName = this.props.itemTabs[0].styleName + " active";
    //     activeItem = this.props.itemTabs[0];
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        activeItem: this.props.activeTab ? this.props.itemTabs[this.props.activeTab] : this.props.itemTabs[0],
        activeid: !this.props.activeTab ? 0 : this.props.activeTab
      });
    }
  }

  componentDidMount() {
    const id = 0;
    /*  this.props.itemTabs.map((item) => {
         item.styleName = item.styleName.replace(" active", "");
      //   item.tabid = id++;
         if (this.props.activeTab && id === this.props.activeTab) {
             this.setState({ activeItem : item,  activeid : id});
         }
        }
     ); */

    //   if (!this.props.activeTab || !this.state.activeItem) {
    this.setState({
      activeItem: this.props.activeTab ? this.props.itemTabs[this.props.activeTab] : this.props.itemTabs[0],
      activeid: !this.props.activeTab ? 0 : this.props.activeTab
    });
    //  }
    this.props.activeTab
      ? ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname}/${this.props.itemTabs[this.props.activeTab].analytics}${
          window.location.search
        }${window.location.hash}`
      })
      : ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname}/${this.props.itemTabs[0].analytics}${window.location.search}${window.location.hash}`
        });
  }

  onTabClick(item, id) {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}/${item.analytics}${window.location.search}${window.location.hash}`
    });
    this.setState({
      activeItem: item,
      activeid: id
    });
    this.props.onTabChange(id);
  }

  render() {
    let id = 0;
    const noDiv = this.props.noDiv === "true";
    const items = this.props.itemTabs.map((item, index) => {
      item.id = id++;
      return (
        <Fragment key={`tabli${index}`}>
          {noDiv &&
          <li className="dummy"/>
          }
          <li className="dummy"/>
          <li data-intro-id={item.dataIntroId}>
            <ButtonRM
              text={item.text}
              key={`tab${index}`}
              styleName={
                item.styleName + (this.state.activeid != -1 && this.state.activeid === item.id ? ' active' : '')
              }
              whenClick={(e) => this.onTabClick(item, item.id)}
            />
          </li>
          {!noDiv &&
            <li className="dummy"/>
          }
        </Fragment>
      );
    });
    return (
      noDiv ?
        <ul>{items}</ul>
        :
        <div className="tabs">
          <ul>{items}</ul>
        </div>
    );
  }
}

export default Tabs;
