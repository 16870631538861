import React, { useContext, useEffect, useRef, useState } from 'react';
import './CatalogExplorer/style.css';
import { findElements, findPath } from '@zert-packages/actions/catalogActions';
import { useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { IntroductionContext } from '@zert-packages/components/common/Introduction';
import TreeWidget from '@zert-packages/components/shared/TreeWidget/TreeWidget';
import { getPlugins } from '@zert-packages/actions/api';
import { hasPermit } from '@zert-packages/utils';
import AdaptableToolbar from '@zert-packages/components/ToolBar/AdaptableToolbar';
import { useResizeObserver } from '@zert-packages/react/hooks/useResizeObserver';
import CatalogElements from './CatalogElements/CatalogElements';
import { populateCatalog } from './CatalogExplorer/CatalogTreeNode';

import CatalogUserPrivileges from './CatalogElements/CatalogUserPrivileges';
import CatalogMetrics from './CatalogExplorer/CatalogMetrics';
import CatalogOriginLink from './CatalogOriginLink';
import useElementActionsCatalog from './useElementActionsCatalog';

function CatalogExplorerPage({ catalogIndex }) {
  //   static contextType = IntroductionContext;
  const dispatch = useDispatch();
  const location = useLocation();

  const { catalog: catalogIntro } = useContext(IntroductionContext);
  const copiedElements = useSelector((state) => state.CORE?.copiedElements);
  const isCuted = useSelector((state) => state.CORE?.isCuted);
  const report_callback = useSelector((state) => state.CORE?.report_callback);
  // const { copiedElements, isCuted, report_callback } = useSelector((state) => state.CORE);
  // const { catalog: catalogList, loadingCatalog: loading, cataloginfos, permits } = useSelector((state) => state);
  const catalogList = useSelector((state) => state.catalog);
  const loading = useSelector((state) => state.loadingCatalog);
  const loadingCatalog = useSelector(
    (state) => state.loadingCatalogs[location.pathname.charAt(location.pathname.length - 1)]
  );
  const cataloginfos = useSelector((state) => state.cataloginfos);
  const permits = useSelector((state) => state.permits);
  const loadingCounters = useSelector((state) => state.loadingCounters);

  const [countersHasLoaded, setCountersHasLoaded] = useState(false);

  const [
    {
      showTree,
      selectedElements,
      showReportConfig,
      //  searchString,
      isShowingFilter,

      isSingleSelectionDialog,
      checkedItems,
      visible,
      catalog,
      selected,
      expandedNodes,
      selectedLabel,
      filterValue,
      expandedNodes: []
    },
    setState
  ] = useState({
    showMetrics: true,
    showTree: true,
    selected: null,
    selectedElements: [],
    showReportConfig: false,
    searchString: '',
    isShowingFilter: false,
    isSingleSelectionDialog: false,
    checkedItems: new Map(),
    visible: false,
    catalog: null,
    selectedLabel: '',

    filterValue: '',
    expandedNodes: []
  });

  const catalogToolbarRef = useRef(null);
  const [, catalogToolbarHeight] = useResizeObserver(catalogToolbarRef);

  useEffect(() => {
    if (!loading) {
      const timeoutId = setTimeout(() => {
        setCountersHasLoaded(true);
      }, 5000);

      return () => clearTimeout(timeoutId);
    } else {
      setCountersHasLoaded(false);
    }
  }, [loading]);

  useEffect(() => {
    setCountersHasLoaded(!loadingCounters);
  }, [loadingCounters]);

  useEffect(() => {
    if (catalogList && catalogList.length > catalogIndex) {
      setState((prevState) => ({ ...prevState, catalog: catalogList[catalogIndex] }));
    }
  }, [catalogList, catalogIndex]);

  useEffect(() => {
    if (catalog) {
      if (localStorage.getItem('selected') == null || catalog.info.id !== localStorage.getItem('selectedCatalog')) {
        localStorage.setItem('selected', `${catalog.rootNode.uuid}^${catalog.rootNode.metadata[0].value}`);
        localStorage.setItem('selectedLabel', catalog.rootNode.label.defaultLabel);
      }
      localStorage.setItem('selectedCatalog', catalog.info.id);

      const expandedNodes = [];
      const expanded = findPath(localStorage.getItem('selected'), catalog, true);
      if (expanded !== null) {
        expanded.map((item) => {
          expandedNodes.push(`${item.key}^${item.value}`);
        });
      } else {
        expandedNodes.push(`${catalog.rootNode.uuid}^${catalog.rootNode.metadata[0].value}`);
      }

      setState((prevState) => ({
        ...prevState,
        expandedNodes,
        selectedLabel: localStorage.getItem('selectedLabel'),
        selected: localStorage.getItem('selected'),
        selectedElements: []
      }));

      dispatch(findElements(localStorage.getItem('selected').split('^')[1], catalog.info.id));
    }
  }, [catalog]);

  const updateFilterValue = (filterValue) => {
    setState((prevState) => ({ ...prevState, filterValue }));
  };

  /*  useEffect(()=>{
    setState(prevState => ({
      ...prevState,
      expandedNodes: expandedNodes,
    }));
  }, [cataloginfos]) */

  /*
  const handleUpload = async (formData, name) => {
    // placeElementNew
    dispatch(uploadFile(formData, catalog, localStorage.getItem('selected').split('^')[1]))


  } */

  const drawCuted = (item) => {
    let opacity = false;
    if (isCuted && copiedElements) {
      copiedElements.forEach((value, key, map) => {
        if (value.versionId === item.versionId) {
          opacity = true;
        }
      });
    }
    return opacity;
  };

  const selectTreeNode = (id, label) => {
    if ((selected !== id || filterValue !== '') && !Array.isArray(label)) {
      setState((prevState) => ({ ...prevState, selected: id, selectedLabel: label, searchString: '' }));
      localStorage.setItem('selected', id);
      localStorage.setItem('selectedLabel', label);
      setState((prevState) => ({ ...prevState, selectedElements: [] }));
      //  selectedElements.clear();
      dispatch(findElements(id.split('^')[1], catalog.info.id));
    }
    if (Array.isArray(label)) {
      setState((prevState) => ({ ...prevState, expandedNodes: label }));
    } else {
      //  const expandedNodes = expandedNodes;
      let remove = null;
      expandedNodes.map((node) => {
        if (node === id && selected === node) {
          remove = id;
        }
      });
      if (remove !== null) {
        expandedNodes.splice(expandedNodes.indexOf(id), 1);
      } else if (expandedNodes.indexOf(id) === -1) {
        expandedNodes.push(id);
      }
      setState((prevState) => ({ ...prevState, expandedNodes: [...expandedNodes] }));
    }
  };

  const handleChange = (e) => {
    const item = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setState((prevState) => ({ ...prevState, checkedItems: prevState.checkedItems.set(item, value) }));
  };

  const selectElement = (versionId, info) => {
    /// if (!selectedElements.find((element)=> (element.versionId ==  versionId))) {
    setState((prevState) => {
      console.log(prevState);
      console.log(versionId);
      const found = prevState.selectedElements.find((element) => element.versionId === versionId);
      if (found) {
        const newState = {
          ...prevState,
          selectedElements: prevState.selectedElements.filter((id) => id.versionId !== versionId)
        };
        return newState;
      }
      const newState = {
        ...prevState,
        selectedElements: [...prevState.selectedElements, { versionId, info }]
      };
      return newState;
    });
    /* } else {
      setState((prevState) => ({
        ...prevState,
        selectedElements: prevState.selectedElements.filter((id) => id.versionId != versionId)
      }));
    } */
  };

  if (loadingCatalog) {
    return <Loader />;
  }

  return (
    <div className="catalog_for_mobile">
      <div
        id="catalog"
        data-intro-id="CatalogExplorerPage.RootNode"
        style={showTree ? { display: 'block' } : { display: 'none' }}
      >
        {catalog && (
          <TreeWidget
            rootNode={populateCatalog(catalog)}
            selectedNodeId={selected}
            expanded={expandedNodes}
            onSelect={selectTreeNode}
            onNodeToggle={selectTreeNode}
          />
        )}
        <MediaQuery query="(max-width: 600px)">
          <div
            style={{
              position: 'absolute',
              top: '60px',
              right: '15px',
              fontSize: '1rem',
              color: '#1799c7'
            }}
            onClick={(e) => {
              setState((prevState) => ({ ...prevState, showTree: false }));
            }}
          >
            <FormattedMessage id="catalog.collapse" defaultMessage="Collapse" />
          </div>
        </MediaQuery>
      </div>
      <CSSTransition
        in={!showReportConfig}
        timeout={300}
        classNames="message"
        unmountOnExit
        onExited={() => {
          setState((prevState) => ({
            ...prevState,
            showButtons: false
          }));
        }}
      >
        <div id="workingArea" style={{ height: '100%' }}>
          <div className="catalog-toolbar" ref={catalogToolbarRef} data-intro-id="CatalogExplorerPage.TopMenu">
            <AdaptableToolbar
              selected={selectedElements}
              selectedNode={selected}
              actions={useElementActionsCatalog}
              showElementActions
              catalogIndex={catalogIndex}
              filterValue={filterValue}
              updateFilterValue={updateFilterValue}
              isShowingFilter={isShowingFilter}
              setIsShowingFilter={(e) =>
                setState((prevState) => ({ ...prevState, isShowingFilter: !prevState.isShowingFilter }))
              }
              snackbarComponent={<CatalogOriginLink versionId={-1} up />}
              disableUpload={loading || !catalog || !countersHasLoaded}
              catalogNode={selected}
            />
          </div>
          <div
            className="catalog_content"
            style={{
              overflow: 'scroll',
              height: `calc(100% - ${catalogToolbarHeight}px)`
            }}
          >
            <div
              className="catalog_caption"
              onClick={(e) => {
                setState((prevState) => ({ ...prevState, showTree: true }));
              }}
            >
              {selectedLabel}
              <MediaQuery query="(max-width: 600px)">
                {!showTree && (
                  <span
                    style={{
                      fontSize: '1rem',
                      color: '#1799c7'
                    }}
                  >
                    {' '}
                    <FormattedMessage id="catalog.expand" defaultMessage="Expand" />{' '}
                  </span>
                )}
              </MediaQuery>
            </div>
            <div
              className="catalog_bread_crumbs"
              onClick={(e) => {
                setState((prevState) => ({ ...prevState, howTree: true }));
              }}
            >
              {catalog && findPath(selected, catalog, false)}
              {filterValue !== '' && (
                <span
                  style={{
                    color: '#cccccc',
                    fontWeight: 'bold',
                    padding: '0 0 0 20px'
                  }}
                >
                  {' '}
                  <FormattedMessage id="catalog.searchfor" defaultMessage="You are searching for:" />
                  <span
                    style={{
                      color: '#1798c7',
                      fontWeight: 'bold'
                    }}
                  >
                    {filterValue}{' '}
                  </span>
                </span>
              )}
            </div>

            {loading && <Loader />}
            {report_callback && (
              <Loader displayText={false}>{report_callback.message && report_callback.message}</Loader>
            )}

            {!loading && (
              <>
                <CatalogUserPrivileges catalog={catalog} selected={selected} />
                {filterValue === '' && <CatalogMetrics catalog={catalog} selected={selected} />}

                {getPlugins()
                  .filter((plugin) => plugin.summaryLabel)
                  .filter((plugin) => hasPermit(plugin, permits))
                  .map((plugin) => (
                    <CatalogElements
                      key={plugin.mimeType}
                      cataloginfos={cataloginfos}
                      selectElement={selectElement}
                      drawCuted={drawCuted}
                      filterValue={filterValue}
                      object={plugin.mimeType}
                      label={plugin.summaryLabel}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </CSSTransition>

      {catalog && catalogIntro.component}
      {catalog && catalogIntro.startIntro()}
    </div>
  );
}

export default withRouter(CatalogExplorerPage);
