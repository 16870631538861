import { v4 as uuidv4 } from 'uuid';
import { showSnackbarChangesSaved, showSnackbarError, showSnackbarInfo } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { addElementsToFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import getLang from '@zert-packages/utils/getLang';
import { createInProgressSnack, createOpenReportSnack } from '@zert-packages/components/common/snackBar';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import {
  updateWorkerStatus,
  handlePost,
  handleQuery,
  fetchRA_Error,
  fetchElementCreated,
  fetchElementCreate,
  updateProgressStatus,
  fetchReportError,
  fetchReportStart,
  fetchRA_Rescent_Success,
  fetchRA_Rescent_Begin,
  handlePostTextAsParam,
  handlePostResultAsString,
  API_ROOT_WAR,
  checkReportStatus
} from '@zert-packages/actions/api';
import { placeElementNew } from '@zert-packages/actions/catalogActions';
import findNodeInProject from './helpers/findNodeInProject';
import findParentNodeInProject from './helpers/findParentNodeInProject';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import {renderInExceptionRoot} from "@zert-packages/utils";
import DuplicateProject from "./DuplicateProject";


export const PROJECT_TEMPLATES_LOADED = 'PROJECT_TEMPLATES_LOADED';
export const START_PROJECT_TEMPLATES_LOAD = 'START_PROJECT_TEMPLATES_LOAD';
const FETCH_Project_BEGIN = 'FETCH_Project_BEGIN';
const FETCH_Project_SUCCESS = 'FETCH_Project_SUCCESS';
const PROJECT_ADD_NODE = 'PROJECT_ADD_NODE';
const PROJECT_ADD_NODE_DONE = 'PROJECT_ADD_NODE_DONE';
const PROJECT_ADD_NODE_UUID = 'PROJECT_ADD_NODE_UUID';

const RENAME_NODE_DONE = 'PROJECT_RENAME_NODE_DONE';
const RENAME_NODE_START = 'PROJECT_RENAME_NODE_START';
const RENAME_NODE = 'PROJECT_RENAME_NODE';

const MOVE_NODE_DONE = 'PROJECT_MOVE_NODE_DONE';
const MOVE_NODE_START = 'PROJECT_MOVE_NODE_START';
const MOVE_NODE = 'PROJECT_MOVE_NODE';

const PASTE_NODE_DONE = 'PROJECT_PASTE_NODE_DONE';
const PASTE_NODE_START = 'PROJECT_PASTE_NODE_START';
const PASTE_NODE = 'PROJECT_PASTE_NODE';

const REMOVE_NODE_DONE = 'PROJECT_REMOVE_NODE_DONE';
const REMOVE_NODE_START = 'PROJECT_REMOVE_NODE_START';
const REMOVE_NODE = 'PROJECT_REMOVE_NODE';

const STORE_PARTICIPANTS_START = 'STORE_PROJECT_PARTICIPANTS_START';
const STORE_BASIS_START = 'STORE_PROJECT_BASIS_START';
const STORE_DESCRIPTION_START = 'STORE_PROJECT_DESCRIPTION_START';

const STORE_PARTICIPANTS_DONE = 'STORE_PROJECT_PARTICIPANTS_DONE';
const STORE_BASIS_DONE = 'STORE_PROJECT_BASIS_DONE';
const STORE_DESCRIPTION_DONE = 'STORE_PROJECT_DESCRIPTION_DONE';

const STORE_PROJECT_DONE = 'STORE_PROJECT_DONE';
const STORE_PROJECT_START = 'STORE_PROJECT_START';

const STORE_PROJECT_ACTIVITY_DONE = 'STORE_PROJECT_ACTIVITY_DONE';
const STORE_PROJECT_ACTIVITY = 'STORE_PROJECT_ACTIVITY';
const STORE_PROJECT_ACTIVITY_START = 'STORE_PROJECT_ACTIVITY_START';
const CONVERT_DONE = 'CONVERT_DONE';
const CONVERT_DONE_SUCCESS = 'CONVERT_DONE_SUCCESS';

const COPY_NODE = 'PROJECT_COPY_NODE';
const CUT_NODE = 'PROJECT_CUT_NODE';
const PROJECT_ADD_COMMENT = 'PROJECT_ADD_COMMENT';
const PROJECT_ADD_COMMENT_START = 'PROJECT_ADD_COMMENT_START';
const PROJECT_REMOVE_COMMENT = 'PROJECT_REMOVE_COMMENT';
const PROJECT_REMOVE_COMMENT_START = 'PROJECT_REMOVE_COMMENT_START';
const PROJECT_EDIT_COMMENT = 'PROJECT_EDIT_COMMENT';
const PROJECT_EDIT_COMMENT_START = 'PROJECT_EDIT_COMMENT_START';
const STORE_SUPPLIERS_DONE = 'STORE_SUPPLIERS_DONE';
const STORE_SUPPLIERS_START = 'STORE_SUPPLIERS_START';
const DUPLICATE_START = 'DUPLICATE_PROJECT_START';
const DUPLICATE_DONE = 'DUPLICATE_PROJECT_DONE';
const CONVERT_START = 'CONVERT_START';
const fetchProjectTemplatesSuccess = (templateslist) => ({
  type: PROJECT_TEMPLATES_LOADED,
  payload: { templateslist }
});

const fetchProjectTemplatesStart = () => ({
  type: START_PROJECT_TEMPLATES_LOAD
});

const fetchProject_Begin = () => ({
  type: FETCH_Project_BEGIN
});

const fetchProject_Success = (project, selectedId) => ({
  type: FETCH_Project_SUCCESS,
  payload: { project, selectedId }
});

const REDUCER_RENAME_NODE_DONE = () => ({
  type: RENAME_NODE_DONE,
  payload: {}
});

const startQuery = '/project';


const exportProjecttsMockUP = () => ({
  name: <FormattedMessage id="Project.Export.Name" defaultMessage="Export project" />,
  reportUUID: uuidv4()
});

export const exportProject =
  (versionId, name) =>
  (dispatch, getState, { snackbar }) => {
    const generatedReport = exportProjecttsMockUP();

    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="Project.PrintManager.Snackbar.ExportProject" defaultMessage="...exporting project" />
    );

    exportProjectAPI(generatedReport.reportUUID, versionId)
      .then((res) => {
        checkReportStatus(res, snackbar, generatingSnackbar, generatedReport, 'zip', null, null, name);
      })
      .catch((e) => {
        showSnackbarError(snackbar, e);
      });
  };

export const exportProjectAPI = (reportUUID, projectVersionId) => {
  const locale = getLang();
  return handlePost(`${startQuery}/publish/${reportUUID}`, { ...reportMockUp, locale, versionId: projectVersionId });
};

const REDUCER_RENAME_NODE = (node_rename_uuid, new_node_name) => ({
  type: RENAME_NODE,
  payload: {
    node_rename_uuid,
    new_node_name
  }
});

const REDUCER_COPY_NODE = (copiedElements) => ({
  type: COPY_NODE,
  payload: {
    copiedElements
  }
});

const REDUCER_CUT_NODE = (copiedElements) => ({
  type: CUT_NODE,
  payload: {
    copiedElements
  }
});
const REDUCER_RENAME_NODE_START = () => ({
  type: RENAME_NODE_START,
  payload: {}
});

const REDUCER_MOVE_NODE_DONE = () => ({
  type: MOVE_NODE_DONE,
  payload: {}
});

const REDUCER_MOVE_NODE = (node_move_uuid, direction, movein, moveout) => ({
  type: MOVE_NODE,
  payload: {
    node_move_uuid,
    direction,
    moveout,
    movein
  }
});

const REDUCER_MOVE_NODE_START = () => ({
  type: MOVE_NODE_START,
  payload: {}
});

const REDUCER_PASTE_NODE_START = () => ({
  type: PASTE_NODE_START,
  payload: {}
});

const REDUCER_PASTE_NODE_DONE = () => ({
  type: PASTE_NODE_DONE,
  payload: {}
});

const REDUCER_PASTE_NODE = (pasted_node, old_uuid, after_pasted_node, iscut) => ({
  type: PASTE_NODE,
  payload: {
    pasted_node,
    old_uuid,
    after_pasted_node,
    iscut
  }
});

const REDUCER_REMOVE_NODE_START = () => ({
  type: REMOVE_NODE_START,
  payload: {}
});

const REDUCER_REMOVE_NODE_DONE = () => ({
  type: REMOVE_NODE_DONE,
  payload: {}
});

const REDUCER_REMOVE_NODE = (node_remove_uuid) => ({
  type: REMOVE_NODE,
  payload: {
    node_remove_uuid
  }
});

const REDUCER_STORE_PARTICIPANTS_START = () => ({
  type: STORE_PARTICIPANTS_START,
  payload: {}
});
const REDUCER_STORE_BASIS_START = () => ({
  type: STORE_BASIS_START,
  payload: {}
});

const REDUCER_STORE_PROJECT_DONE = (id) => ({
  type: STORE_PROJECT_DONE,
  payload: { id }
});

const REDUCER_STORE_PROJECT_START = (id) => ({
  type: STORE_PROJECT_START,
  payload: { id }
});

const REDUCER_STORE_PROJECT_ACTIVITY_DONE = (id, uuid) => ({
  type: STORE_PROJECT_ACTIVITY_DONE,
  payload: { id }
});

const REDUCER_STORE_PROJECT_ACTIVITY_START = (id, uuid) => ({
  type: STORE_PROJECT_ACTIVITY_START,
  payload: {
    id,
    uuid
  }
});

const REDUCER_STORE_DESCRIPTION_START = () => ({
  type: STORE_DESCRIPTION_START,
  payload: {}
});

const REDUCER_STORE_PARTICIPANTS_DONE = () => ({
  type: STORE_PARTICIPANTS_DONE,
  payload: {}
});
const REDUCER_STORE_BASIS_DONE = () => ({
  type: STORE_BASIS_DONE,
  payload: {}
});

const REDUCER_STORE_DESCRIPTION_DONE = () => ({
  type: STORE_DESCRIPTION_DONE,
  payload: {}
});

const REDUCER_STORE_SUPPLIERS_DONE = () => ({
  type: STORE_SUPPLIERS_DONE,
  payload: {}
});

const REDUCER_STORE_SUPPLIERS_START = () => ({
  type: STORE_SUPPLIERS_START,
  payload: {}
});

const REDUCER_DUPLICATE_START = () => ({
  type: DUPLICATE_START,
  payload: {}
});

const REDUCER_CONVERT_START = () => ({
  type: CONVERT_START,
  payload: {}
});

const REDUCER_DUPLICATE_DONE = (info) => ({
  type: DUPLICATE_DONE,
  payload: { info }
});



export function convertToProjectTemplate(versionId, name) {
  return function (dispatch, getState, hooks) {
    const generatingSnackbar = createInProgressSnack(
        hooks.snackbar,
        <FormattedMessage
            id="Project.Template.GeneratingLabel"
            defaultMessage="Converting to template..."
        />
    );
    handlePostTextAsParam(`/project/createProjectTemplate` + `/${versionId}`, name)
      .then((result) => {
        return updateWorkerStatus(result, hooks);
      })

      .then((info) => {
        if (info != null) {
          hooks.snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarInfo(hooks.snackbar,    <FormattedMessage id="prtoject.convertToTemplate.done" defaultMessage="Template created" />);
        } else {
          hooks.snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarError(
            hooks.snackbar,
            <FormattedMessage id="project.convertToTemplate.error" defaultMessage="Template creation error" />
          );

        }
      })

      .catch((error) => {
        hooks.snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(hooks.snackbar, error.message);
        // dispatch(fetchRA_Error(error));
      });
    return dispatch(REDUCER_CONVERT_START());
  };
}

export function duplicateProject(versionId, history) {
  return function (dispatch, getState, hooks) {
    const generatingSnackbar = createInProgressSnack(
        hooks.snackbar,
        <FormattedMessage
            id="Project.Duplicate.GeneratingLabel"
            defaultMessage="Duplicating project..."
        />
    );
    renderInExceptionRoot(DuplicateProject, {


      onFinish: (name) => (handlePostTextAsParam(`/project/duplicateProject` + `/${versionId}`, name)
          .then((result) => {
            return updateWorkerStatus(result, hooks);
          })

          .then((info) => {
            hooks.snackbar.closeSnackbar(generatingSnackbar);
            showSnackbarInfo(hooks.snackbar, <FormattedMessage
                id="Project.Project.Duplicate.Successful"
                defaultMessage="Duplicating successful"
            />)
            history.push(`${API_ROOT_WAR}/project/${info.versionId}`)
            dispatch(loadProject(info.versionId));
            dispatch(getVersionInfo(info.versionId))
          })

          .catch((error) => {
            showSnackbarError(
                hooks.snackbar,
                error
            );
          }))
    });
    //return dispatch(REDUCER_DUPLICATE_START());
  };
}


export const generateActionPlanReport =
  (report, versionId, reportType) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RiskAssement.GeneratingLabelCommon" defaultMessage="...generating report" />
    );
    const newReport = {
      ...report,
      reportUUID: uuidv4(),
      locale
    };
    handlePost(`/report-react/generateReport/${newReport.reportUUID}/${reportType}`, {
      ...reportMockUp,
      versionId
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export function generateProjectOverviewReport(report, versionId) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

  return function (dispatch) {
    handleQuery(`/project/generateOverviewReport/${report.reportUUID}/${versionId}/${locale}`)
      .then((callback) => {
        dispatch(updateProgressStatus(callback, report));
      })

      .catch((error) => {
        dispatch(fetchReportError(error));
      });
    return dispatch(fetchReportStart(report));
  };
}

export function generateActivityReport(report, versionId) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

  return function (dispatch) {
    handleQuery(`/project/generateActivityReport/${report.reportUUID}/${versionId}/${locale}`)
      .then((callback) => {
        dispatch(updateProgressStatus(callback, report));
      })

      .catch((error) => {
        dispatch(fetchReportError(error));
      });
    return dispatch(fetchReportStart(report));
  };
}

export function copyNode(selectedNodes, isCuted) {
  return function (dispatch) {
    if (isCuted) {
      return dispatch(REDUCER_CUT_NODE(selectedNodes));
    }
    return dispatch(REDUCER_COPY_NODE(selectedNodes));
  };
}

export function moveNode(id, uuid, direction, moveout, movein) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/moveNode/${id}/${uuid}/${direction}/${movein}/${moveout}`)
      .then(() => {

          const node = findNodeInProject(getState().project, uuid);
          const parent = findParentNodeInProject(getState().project, uuid);

          if (movein || moveout) {
            if (node != null && parent != null) {
              if (movein) {
                const pos = parent.activities.indexOf(node);
                if (pos >= 0) {
                  parent.activities[pos - 1].activities.push(node);
                  parent.activities.splice(pos, 1);

                }
              } else if (moveout) {
                let parentofParent = findParentNodeInProject(getState().project, parent.uuid);
                if (parentofParent == null) {
                  parentofParent = getState().project;
                }
                if (parentofParent != null) {
                  const pos = parent.activities.indexOf(node);
                  parent.activities.splice(pos, 1);
                  // RiskAnalysis.Node movetoNode =  parent.getChildren().get(pos);
                  parentofParent.activities.splice(parentofParent.activities.indexOf(parent) + 1, 0, node);

                  //    this.forceUpdate();
                }
              }
            } else if (
                node != null &&
                parent == null &&
                movein &&
                getState().project.activities.indexOf(node) > 1
            ) {
              const pos = getState().project.activities.indexOf(node);
              getState().project.activities.splice(pos, 1);
              // RiskAnalysis.Node movetoNode =  parent.getChildren().get(pos);
              getState().project.activities[pos - 1].activities.push(node);

            }
          } else if (node != null && parent != null) {
            if (direction) {
              const pos = parent.activities.indexOf(node);
              node.focused = true;
              if (pos < parent.activities.length) {
                parent.activities.splice(pos, 1);
                parent.activities.splice(pos + 1, 0, node);

                //   this.forceUpdate();
              }
            } else {
              const pos = parent.activities.indexOf(node);
              if (pos > 0) {
                parent.activities.splice(pos, 1);
                parent.activities.splice(pos - 1, 0, node);

                // this.forceUpdate();
              }
            }
          } else if (node != null) {
            if (direction) {
              const pos = getState().project.activities.indexOf(node);
              node.focused = true;
              if (pos < getState().project.activities.length) {
                getState().project.activities.splice(pos, 1);
                getState().project.activities.splice(pos + 1, 0, node);

                //   this.forceUpdate();
              }
            } else {
              const pos = getState().project.activities.indexOf(node);
              if (pos > 0) {
                getState().project.activities.splice(pos, 1);
                getState().project.activities.splice(pos - 1, 0, node);

                // this.forceUpdate();
              }
            }
          }
        dispatch(fetchProject_Success({...getState().project, activities : getState().project.activities}, node.uuid));


     //   dispatch(REDUCER_MOVE_NODE(uuid, direction, movein, moveout));
   //     dispatch(REDUCER_MOVE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_MOVE_NODE_START());
  };
}

export function pasteNode(id, uuid, afteruuid, iscut) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/pasteNode/${id}/${uuid}/${afteruuid}/${iscut}`)
      .then((node) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(loadProject(getState().project.info.versionId));

      //  dispatch(REDUCER_PASTE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PASTE_NODE_START());
  };
}

export function duplicateNode(id, uuid, afteruuid) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/duplicateNode/${id}/${uuid}/${afteruuid}/${false}`)
      .then((node) => {
        showSnackbarChangesSaved(hooks.snackbar);
        //dispatch(REDUCER_PASTE_NODE(node, uuid, afteruuid, false));
        dispatch(loadProject(getState().project.info.versionId));
   //     dispatch(getVersionInfo(getState().project.info.versionId));
    //    dispatch(REDUCER_PASTE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PASTE_NODE_START());
  };
}

export function removeNode(id, uuid) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/removeNode/${id}/${uuid}`)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        const existingNode = findNodeInProject(getState().project, uuid);
        let nodeUUID = null;
        if (existingNode != null) {
          const parentNode = findParentNodeInProject(getState().project, uuid);

          if (parentNode != null) {
            nodeUUID = parentNode.uuid;
            const index = parentNode.activities.indexOf(existingNode);
            if (index >= 0) {
              parentNode.activities.splice(index, 1);

            }
          } else {
            const index = getState().project.activities.indexOf(existingNode);
            if (index >= 0) {
              getState().project.activities.splice(index, 1);
            }
            nodeUUID =getState().project.activities[index - 1].uuid;
          }
        }
        dispatch(fetchProject_Success({...getState().project, activities : getState().project.activities}, nodeUUID));

        //dispatch(REDUCER_REMOVE_NODE(uuid));
   //     dispatch(REDUCER_REMOVE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_REMOVE_NODE_START());
  };
}

export function storeProjectSuppliers(idAssesment, suppliers) {
  return function (dispatch, getState, hooks) {
    handlePostResultAsString(`/project/storeProjectSuppliers/${idAssesment}`, suppliers)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_SUPPLIERS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_SUPPLIERS_START());
  };
}

export function storeProjectParticipants(idAssesment, participants) {
  return function (dispatch, getSstate, hooks) {
    handlePostResultAsString(`/project/storeProjectParticipants/${idAssesment}`, participants)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_PARTICIPANTS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_PARTICIPANTS_START());
  };
}

export function storeActivityBasises(idAssesment, basises, activityId) {
  return function (dispatch, getState, hooks) {
    handlePostResultAsString(`/project/storeBasises/${idAssesment}/${activityId}`, basises)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_BASIS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_BASIS_START());
  };
}

export function storeProjectBasises(idAssesment, basises) {
  return function (dispatch, getState, hooks) {
    handlePostResultAsString(`/project/storeBasises/${idAssesment}`, basises)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_BASIS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_BASIS_START());
  };
}

export function storeProjectActivity(idAssesment, activity) {
  return function (dispatch, getState, hooks) {
    handlePostResultAsString(`/project/storeProjectActivity/${idAssesment}/${activity.uuid}`, activity)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_PROJECT_ACTIVITY_DONE(idAssesment, activity.uuid));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_PROJECT_ACTIVITY_START(idAssesment, activity.uuid));
  };
}

export function storeProjectDescription(idAssesment, uuid, description) {
  return function (dispatch, getState, hooks) {
    handlePostTextAsParam(`/project/storeProjectNodeDesc/${idAssesment}/${uuid}`, description)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_DESCRIPTION_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_DESCRIPTION_START());
  };
}

export function storeProjectActivityName(id, uuid, data) {
  return function (dispatch, getState, hooks) {
    handlePostTextAsParam(`/project/storeProjectNodeName/${id}/${uuid}`, data)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_RENAME_NODE(uuid, data));
        dispatch(REDUCER_RENAME_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_RENAME_NODE_START());
  };
}

export function storeProject(id, project) {
  const idElement = -1;
  return function (dispatch, getState, hooks) {
    handlePostResultAsString(`/project/storeProjectDetails/${id}`, project)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_PROJECT_DONE(id));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_PROJECT_START());
  };
}

const fetchAddNewSection = (ra_node, uuid, sibling) => ({
  type: PROJECT_ADD_NODE,
  payload: {
    ra_node,
    uuid,
    sibling
  }
});

const fetchAddNewSectionDone = () => ({
  type: PROJECT_ADD_NODE_DONE,
  payload: {}
});

const fetchAddNewSectionUUID = (uuid) => ({
  type: PROJECT_ADD_NODE_UUID,
  payload: { uuid }
});

const REDUCER_COMMENT_DONE = (activityUUID, comId, versionId, parentComId, comment) => ({
  type: PROJECT_ADD_COMMENT,
  payload: {
    activityUUID,
    comId,
    versionId,
    parentComId,
    comment
  }
});

const REDUCER_COMMENT_START = (activityUUID, versionId, parentComId, comment) => ({
  type: PROJECT_ADD_COMMENT_START,
  payload: {
    activityUUID,
    versionId,
    parentComId,
    comment
  }
});

const REDUCER_COMMENT_REMOVE_DONE = (activityUUID, comId, result) => ({
  type: PROJECT_REMOVE_COMMENT,
  payload: {
    activityUUID,
    comId,
    result
  }
});

const REDUCER_COMMENT_REMOVE_START = (activityUUID, comId) => ({
  type: PROJECT_REMOVE_COMMENT_START,
  payload: {
    activityUUID,
    comId
  }
});

const REDUCER_COMMENT_EDIT_DONE = (activityUUID, comId, comment) => ({
  type: PROJECT_EDIT_COMMENT,
  payload: {
    activityUUID,
    comId,
    comment
  }
});

const REDUCER_COMMENT_EDIT_START = (activityUUID, comId, comment) => ({
  type: PROJECT_EDIT_COMMENT_START,
  payload: {
    activityUUID,
    comId,
    comment
  }
});

export function addNewComment(activityUUID, versionId, parentComId, comment, callback) {
  return function (dispatch, getState, hooks) {
    const url = activityUUID
      ? `/project/addComment/${versionId}/${parentComId}/${activityUUID}`
      : `/project/addComment/${versionId}/${parentComId}`;
    handlePost(url, comment)
      .then((result) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_COMMENT_DONE(activityUUID, result.comId, versionId, parentComId, comment));
        if (callback) {
          callback(result);
        }
      })
      .catch((err) => {
        showSnackbarError(hooks.snackbar, <FormattedMessage id="comments.error" defaultMessage="An error occured. Please copy your comment and try again later" />);
      });

    return dispatch(REDUCER_COMMENT_START(activityUUID, versionId, parentComId, comment));
  };
}

export function archiveComment(activityUUID, comId, archive, callback) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/archiveComment/${comId}/${archive}`)
      .then((result) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_COMMENT_REMOVE_DONE(activityUUID, comId, result));
        if (callback) {
          callback(archive);
        }
      })
      .catch((err) => {
        showSnackbarError(hooks.snackbar, err);
      });
    return dispatch(REDUCER_COMMENT_REMOVE_START(activityUUID, comId));
  };
}

export function editComment(activityUUID, comId, comment, callback) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/editComment/${comId}`, comment)
      .then(() => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_COMMENT_EDIT_DONE(activityUUID, comId, comment));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        showSnackbarError(hooks.snackbar, <FormattedMessage id="comments.error" defaultMessage="An error occured. Please copy your comment and try again later" />);
      });
    return dispatch(REDUCER_COMMENT_EDIT_START(activityUUID, comId, comment));
  };
}

export function addNewSection(versionId, after_uuid, sibling, activityName, activityDesc) {
  return function (dispatch, getState, hooks) {
    handlePost(`/project/addNewSection/${versionId}/${after_uuid}/${sibling}/${activityName}/${activityDesc}`)
      .then((node) => {
        showSnackbarChangesSaved(hooks.snackbar);

         const myNode = findNodeInProject(getState().project, after_uuid);
          if (myNode != null) {
            node.focused = true;
            if (!sibling) {
              myNode.activities.push(node);
            } else {
              const parentNode = findParentNodeInProject(getState().project, after_uuid);
              if (parentNode != null) {
                const index = parentNode.activities.indexOf(myNode);
                parentNode.activities.splice(index + 1, 0, node);
              } else {
                const index = getState().project.activities.indexOf(myNode);
                getState().project.activities.splice(index + 1, 0, node);
              }
            }
          } else {
            getState().project.activities.splice(0, 0, node);
          }
          dispatch(fetchProject_Success({...getState().project, activities : getState().project.activities}, node.uuid));
       //   history.push(`${API_ROOT_WAR}/project/${getState().project.info.versionId}/${node.uuid}`);
          //this.setState({ selectedNode: this.props.ra_node });

     // dispatch(fetchAddNewSectionDone(project));
    })
      .catch((err) => {

        showSnackbarError(hooks.snackbar, err);
      });
    return dispatch(fetchAddNewSectionUUID(after_uuid));
  };
}

export function initProject(idTemplate, data, placement1, folderId, versionId, activityId) {
  let idElement = -1;
  return function (dispatch, getState, hooks) {
    handlePost(`/project/initProject/${idTemplate}`, data)
      .then((result) => {
        return updateWorkerStatus(result, hooks);
      })
      .then((info) => {
        idElement = info.versionId;
        if (folderId) {
          return addElementsToFolderApi(folderId, [idElement]);
        }
        return null;
      })
      .then(() => {
        return placeElementNew(idElement, placement1);
      })
      .then(() => {
        dispatch(fetchElementCreated(idElement, versionId, activityId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchElementCreate());
  };
}

export function loadRescentElements(showCurrent, showPast) {
  return function (dispatch) {
    handleQuery(`/project/rescent/${showCurrent}/${showPast}`)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function loadProject(versionId) {
  return function (dispatch) {
    handleQuery(`/project/load/${versionId}`)
      .then((project) => {
        dispatch(fetchProject_Success(project));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchProject_Begin());
  };
}

export function searchElements(showPast, search) {
  return function (dispatch) {
    handlePostTextAsParam(`/project/search/${showPast}`, search)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function loadProjectTemplates() {
  return function (dispatch) {
    handleQuery('/project/getAllTemplates')
      .then((templates) => {
        dispatch(fetchProjectTemplatesSuccess(templates));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(fetchProjectTemplatesStart());
  };
}

const DUPLICATE_DONE_SUCCESS = 'DUPLICATE_DONE_SUCCESS';
const CLOSE_PROJECT_START = 'REDUCER_CLOSE_PROJECT_START';
const CLOSE_PROJECT = 'REDUCER_CLOSE_PROJECT';
const CLOSE_PROJECT_DONE = 'REDUCER_CLOSE_PROJECT_DONE';

const REDUCER_CLOSE_PROJECT_START = (versionId) => ({
  type: CLOSE_PROJECT_START,
  payload: { versionId }
});


export function closeProject(versionId, history) {
  return function (dispatch, getState, hooks) {
    const generatingSnackbar = createInProgressSnack(
        hooks.snackbar,
        <FormattedMessage
            id="Project.Project.GeneratingLabel"
            defaultMessage="Closing project"
        />
    );
    handleQuery(`/project/closeProject/${versionId}`)
      .then((project) => {
        hooks.snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarInfo(  hooks.snackbar,  <FormattedMessage
            id="Project.Project.Close.Successful"
            defaultMessage="Closing successful"
        />)
        dispatch(loadProject(project.versionId));
        dispatch(getVersionInfo(project.versionId))
        history.push(`${API_ROOT_WAR}/project/${project.versionId}`)

      })
      .catch((error) => {
        hooks.snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError( hooks.snackbar, error)
      });
    return dispatch(REDUCER_CLOSE_PROJECT_START(versionId));
  };
}


export function openProject(versionId, history) {
  return function (dispatch, getState, hooks) {
    const generatingSnackbar = createInProgressSnack(
        hooks.snackbar,
        <FormattedMessage
            id="Project.Project.Open"
            defaultMessage="Opening project"
        />
    );
    handleQuery(`/project/closeProject/${versionId}`)
      .then((project) => {
        hooks.snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarInfo(  hooks.snackbar,  <FormattedMessage
            id="Project.Project.Open.Successful"
            defaultMessage="Opening successful"
        />)
        dispatch(loadProject(project.versionId));
        dispatch(getVersionInfo(project.versionId))
        history.push(`${API_ROOT_WAR}/project/${project.versionId}`)

      })
      .catch((error) => {
        hooks.snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError( hooks.snackbar, error)
      });
    return dispatch(REDUCER_CLOSE_PROJECT_START(versionId));
  };
}

export function projectReducer(state, action) {
  switch (action.type) {
    case COPY_NODE:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: false
      };
    case CONVERT_START:
      return {
        ...state,
        projectTemplate: true
      };
    case CUT_NODE:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: true
      };
    case RENAME_NODE_DONE:
      return {
        ...state,
        node_rename_uuid: null,
        new_node_name: null,
        renaming_node: false
      };
    case RENAME_NODE_START:
      return {
        ...state,
        node_rename_uuid: null,
        new_node_name: null,
        renaming_node: true
      };
    case RENAME_NODE:
      return {
        ...state,
        node_rename_uuid: action.payload.node_rename_uuid,
        new_node_name: action.payload.new_node_name
      };

    case MOVE_NODE_DONE:
      return {
        ...state,
        move_node_uuid: null,
        move_node_direction: null,
        move_node_moveout: null,
        move_node_movein: null
      };
    case MOVE_NODE_START:
      return {
        ...state,
        move_node_uuid: null,
        move_node_direction: null,
        move_node_moveout: null,
        move_node_movein: null
      };
    case MOVE_NODE:
      return {
        ...state,
        move_node_uuid: action.payload.node_move_uuid,
        move_node_direction: action.payload.direction,
        move_node_moveout: action.payload.moveout,
        move_node_movein: action.payload.movein
      };

    case REMOVE_NODE_DONE:
      return {
        ...state,
        node_remove_uuid: null
      };
    case REMOVE_NODE_START:
      return {
        ...state,
        node_remove_uuid: null
      };
    case REMOVE_NODE:
      return {
        ...state,
        node_remove_uuid: action.payload.node_remove_uuid
      };

    case PASTE_NODE_DONE:
      return {
        ...state,
        iscut: false,
        pasted_node: null,
        old_uuid: null,
        copiedElements: []
      };
    case PROJECT_ADD_COMMENT_START:
      return {
        ...state,
        addingcomment: true,
        versionId: action.payload.versionId,
        parentCommentUUID: action.payload.parentCommentUUID,
        activityUUID: action.payload.activityUUID
      };
    case PROJECT_ADD_COMMENT:
      return {
        ...state,
        addingcomment: false,
        versionId: action.payload.versionId,
        parentCommentUUID: action.payload.parentCommentUUID,
        activityUUID: action.payload.activityUUID,
        added_comment: action.payload.comment
      };
    case PASTE_NODE_START:
      return {
        ...state
      };
    case PASTE_NODE:
      return {
        ...state,
        pasted_node: action.payload.pasted_node,
        old_uuid: action.payload.old_uuid,
        after_pasted_node: action.payload.after_pasted_node,
        iscut: action.payload.iscut
      };

    case PROJECT_ADD_NODE_DONE:
      return {
        ...state,
        node_click_uuid: null,
        sibling: null,
        ra_node: null
      };
    case PROJECT_ADD_NODE:
      return {
        ...state,
        node_click_uuid: action.payload.uuid,
        sibling: action.payload.sibling,
        ra_node: action.payload.ra_node
      };


    case PROJECT_TEMPLATES_LOADED:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.

      return {
        ...state,
        error: null,
        projecttemplates: action.payload.templateslist,
        loadingTemplates: false
      };

    case FETCH_Project_BEGIN:
      return {
        ...state,
        loadingProject: true,

        project: null
      };
    case FETCH_Project_SUCCESS:
      return {
        ...state,
        loadingProject: false,
        selectedId: action.payload.selectedId,
        project: action.payload.project
      };

    case DUPLICATE_START:
      return {
        ...state,
        duplicateProject: true
      };

    case CLOSE_PROJECT_DONE:
      return {
        ...state,
        closingProject: false,
        newProject: null
      };

    case CLOSE_PROJECT_START:
      return {
        ...state,
        closingProject: true,
        newProject: null
      };

    case CLOSE_PROJECT:
      return {
        ...state,
        closingProject: false,
        newProject: action.payload.project
      };

    case DUPLICATE_DONE:
      return {
        ...state,
        duplicateProject: false,
        duplicated_project: action.payload.info
      };

    case DUPLICATE_DONE_SUCCESS:
      return {
        ...state,
        duplicateProject: false,
        duplicated_project: null
      };

    case CONVERT_DONE:
      return {
        ...state,
        projectTemplate: false,
        converted_projectTemplate: action.payload.info
      };

    case CONVERT_DONE_SUCCESS:
      return {
        ...state,
        projectTemplate: false,
        converted_projectTemplate: null
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
