import { handlePost, handleQuery } from '@zert-packages/actions/api';

export const getAllTemplates = (isChecklist) => {
  return handleQuery(`/checklist-react/getAllTemplates/${isChecklist}`);
};

export const getTemplatesBundle = (versionId) => {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handleQuery(`/checklist-react/getTemplates/${locale}/${versionId}`);
};
export const initChecklistAPI = (idTemplate = -1, data) =>
  handlePost(`/demandProjects/initCheckList/${idTemplate}`, data);

export const initSafetyInspectionAPI = (idTemplate = -1, data) =>
  handlePost(`/checklist-react/initSafetyInspection/${idTemplate}`, data);

export const initProjectAPI = (idTemplate = -1, data) =>
  handlePost(`/project/initProject/${idTemplate}`, data);
