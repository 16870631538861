import './ElementOverview.css';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_ACTIONS_TYPES } from '@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS';
import { API_ROOT_WAR, fetchRA_Rescent_Success } from '@zert-packages/actions/api';
import ReactDOM from 'react-dom';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Tabs from '@zert-packages/components/shared/Tabs';
import TileList from '@zert-packages/components/ElementTile/TileList';
import ButtonRM from '@zert-packages/components/shared/Button';
import TextareaAutosize from 'react-autosize-textarea';
import InfoLabel from '@zert-packages/components/shared/InfoLabel/InfoLabel';
import { usePrevious } from '@zert-packages/react/hooks/usePrevious';
import { columns as columnDefs, additionalColumns} from "@zert-packages/components/ElementTile/ElementTileTable";
import {Button} from "@mui/material";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const columnMap = new Map(columnDefs.map(e => [e.identifier, e]));
additionalColumns.forEach((e) => {
  columnMap.set(e.identifier, e);
})
function getColumnsByMime(mimeType: string) {
  let retList:Array<string> = [];
  switch (mimeType) {
    case 'safety-inspection':
      retList = ['nameNew', 'creator', 'owner', 'date', 'lastCompletedDate', 'status', 'id'];
      break;
    case 'checklist':
    case 'deviation':
    case 'project':
    case 'projectplanning':
    case 'risk-analysis':
      retList = ['nameNew', 'creator', 'owner', 'date', 'status', 'id'];
      break;
    default:
      return retList = ['nameNew', 'creator', 'owner', 'date', 'label', 'status', 'id'];
  }
  return retList.map(e => columnMap.get(e));
}

function ElementOverview(props) {
  const { pluginMimeType, plugins, showExplorer, defaultValue } = props;
  const tasks = useSelector((state) => state.rescentRiskAnalises);
  const loading = useSelector((state) => state.loading);
  const permits = useSelector((state) => state.permits);

  const [plugin, setPlugin] = useState<any>(null);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [searchingForString, setSearchingForString] = useState<string | null>(null);
  const [editingStart, setEditingStart] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);

  const prevActiveTab = usePrevious(activeTab);

  const dispatch = useDispatch();

  const nameInput = useRef<any>();

  const clearSearch = () => {
    setSearchingForString(null);
    setSearchString('');
    loadRecentElements();
  };

  const onChange = (e) => {
    setEditingStart(true);
    setSearchString(e.target.value);
  };

  const handleLoadElement = ({ actionType, onFinishState }) => {
    if (actionType === TASK_ACTIONS_TYPES.duplicate) {
      const duplicatedElement = onFinishState.left;
      if (duplicatedElement != null) {
        dispatch(fetchRA_Rescent_Success([duplicatedElement, ...tasks]));
        return;
      }
    }
    loadRecentElements();
  };

  const loadRecentElements = () => {
    if (plugin && typeof activeTab !== 'undefined') {
      const pluginRoute = plugin.routes.find((e) => e.isOverview);
      dispatch(pluginRoute.recentAction(activeTab == 0, activeTab == 1));
    }
  };

  const switchTab = (object) => {
    setActiveTab(object);
  };

  const focusInput = (component) => {
    if (component) {
      const el = ReactDOM.findDOMNode(component);
      if (el && el instanceof HTMLElement) {
        el.focus();
      }
    }
  };

  useEffect(() => {
    if (pluginMimeType && plugins && !plugin) {
      const plugin = plugins.find((e) => e.mimeType === pluginMimeType);
      if (plugin) {
        setPlugin(plugin);
      }
    }
  }, []);

  useEffect(() => {
    if (pluginMimeType) {
      setColumns(getColumnsByMime(pluginMimeType));
    } else if (plugin?.mimeType) {
      setColumns(getColumnsByMime(plugin.mimeType));
    }
  }, [plugin, pluginMimeType]);

  useEffect(() => {
    if (plugin) {
      loadRecentElements();
    }
  }, [plugin]);

  useEffect(() => {
    if (activeTab !== prevActiveTab) {
      loadRecentElements();
    }
  }, [activeTab]);

  const startingPlace = showExplorer ? `${API_ROOT_WAR}/explorer` : API_ROOT_WAR;

  const tabsList = [
    {
      text: <FormattedMessage id="ElementOverview.Recent" defaultMessage="Recent" />,
      styleName: 'btn',
      analytics: 'ongoing'
    },
    {
      text: <FormattedMessage id="ElementOverview.Past" defaultMessage="Past" />,
      styleName: 'btn',
      analytics: 'finalized'
    }
  ];

  if (!plugin) {
    return <Loader />;
  }

  return (
    <div className="content-cage">
      <div className="tabs">
        <Button
          exact
          to={API_ROOT_WAR + plugin.newpath}
          component={NavLink}
          startIcon={<PlaylistAddIcon color="inherit" />}
          disableRipple={true}
        >
          {plugin.newlabel}
        </Button>
        <Tabs itemTabs={tabsList} onTabChange={switchTab} activeTab={activeTab} noDiv={"true"} />
      </div>
      <div className="inner-cage">
        {searchingForString && (
          <InfoLabel text="">
            <span className="searchtext-wrapper">
              <FormattedMessage
                id="ElementOverview.search.string"
                defaultMessage="You searched for: {searchingForString}"
                values={{ searchingForString }}
              />
              <span className="clear-search" onClick={clearSearch}>
                <FormattedMessage id="ElementOverview.search.clearSearch" defaultMessage="Clear search" />
              </span>
            </span>
          </InfoLabel>
        )}

        {(loading || tasks == null) && (
          <Loader>
            <FormattedMessage
              id="ElementOverview.loading"
              defaultMessage="{elements}"
              values={{ elements: plugin.namePlural }}
            />
          </Loader>
        )}

        {tasks !== null && tasks.length > 0 && (
          <TileList
            explorer_infos={tasks}
            loading={loading}
            reloadElement={handleLoadElement}
            orderBy="date"
            columnsNew={columns ? columns : undefined}
            showCut={undefined}
            bradCrumbComponent={undefined}
          />
        )}

        {(tasks === null || tasks.length === 0) && !loading && (
          <div className="action-plan-empty" style={{ height: tasks === null ? '100%' : '' }}>
            <div className="vertical-group align-center">
              {permits[plugin.newpermit] && permits[plugin.newpermit] === 'true' && (
                <div style={{ marginBottom: '25px' }}>
                  <NavLink exact activeClassName="current" to={startingPlace + plugin.newpath}>
                    <ButtonRM styleName="primary" text="">
                      <FormattedMessage
                        id="ElementOverview.create"
                        defaultMessage="New {element}"
                        values={{ element: plugin.name }}
                      />
                    </ButtonRM>
                  </NavLink>
                </div>
              )}
              <h2 className="title">
                <FormattedMessage
                  id="ElementOverview.nothing.found"
                  defaultMessage="No more {elements} can be found."
                  values={{ elements: plugin.namePlural }}
                />
              </h2>
              <h4 className="sub-title">
                <FormattedMessage
                  id="ElementOverview.nothing.foundcategory"
                  defaultMessage="Can’t find what you’re looking for?"
                />
              </h4>

              <div
                className="auto-grow-input info-label"
                onClick={() => {
                  focusInput(nameInput.current);
                }}
              >
                <TextareaAutosize
                  style={{ backgroundColor: 'transparent' }}
                  ref={nameInput}
                  minRows={1}
                  defaultValue={defaultValue}
                  onChange={(e) => onChange(e)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (searchString && searchString != '') {
                        setSearchingForString(searchString);
                        const pluginRoute = plugin.routes.find((e) => e.isOverview);
                        dispatch(pluginRoute.searchAction(activeTab == 1, searchString));
                      }
                    }
                  }}
                  required={!editingStart}
                />
                <span
                  className="text-placeholder"
                  style={{ left: '20px' }}
                  onClick={(e) => {
                    focusInput(nameInput.current);
                  }}
                >
                  <FormattedMessage
                    id="ElementOverview.nothing.search_for_name_or_id"
                    defaultMessage="Search for name or id here"
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ElementOverview;
