import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { format as FNSFormat } from 'date-fns';
import { stateToHTML } from 'draft-js-export-html';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import { loadQuestinaireTemplates } from '../SafetyInspectionPlugin/serviceMidleware';
import { initChecklistAPI } from '../SafetyInspectionPlugin/API';

const formattedMessages = {
  dialogTitle: (
    <FormattedMessage id="headbar.title.createnewchecklist" defaultMessage="Create checklist" />
  ),
  confirm: <FormattedMessage id="RMP.DetailView.CreateChecklistDialog.Confirm" defaultMessage="Create" />,
  cancel: <FormattedMessage id="RMP.DetailView.CreateChecklistDialog.Cancel" defaultMessage="Cancel" />,
  checklistTitle: <FormattedMessage id="RMP.CreateChecklistDialog.ChecklistTitle" defaultMessage="Title" />,
  locationOfElement: <FormattedMessage id="RMP.CreateChecklistDialog.LocationLabel" defaultMessage="Location" />,
  questionnaireTemplate: (
    <FormattedMessage
      id="RMP.CreateChecklistDialog.QuestionnaireTemplateLabel"
      defaultMessage="Questionnaire Template"
    />
  ),
  placementLabel: <FormattedMessage id="RMP.CreateChecklistDialog.PlacementLabel" defaultMessage="Placement" />,
  descriptionLabel: <FormattedMessage id="RMP.CreateChecklistDialog.DescriptionLabel" defaultMessage="Description" />
};

const useStyles = makeStyles({
  DialogContent: {
    display: 'grid',
    gridTemplateRows: '50px 58px 38px 370px',
    alignItems: 'flex-start',
    gridGap: 20,
    padding: 20,
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  fieldContainerWithLabel: {
    padding: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  }
});
function CreateChecklistDialog({ handleClose, onCreate, location }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.QUESTIONNAIRE);
  const [creating, setCreating] = useState(false);
  const catalog = useSelector((state) => state.catalog);
  const [state, setState] = useState({
    idtemplate: -1,
    name: '',
    description: ''
  });

  const updateStateByField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const onChangeQuestionnaireTemplate = (e) => {
    const { value } = e.target;
    const todayDate = FNSFormat(new Date(), 'yyyy-MM-dd');
    const template = templates.find((template) => value === template.versionId);
    setState((prev) => ({
      ...prev,
      idtemplate: e.target.value,
      name: `${template.name} ${todayDate}`,
      description: template && template.description ? getTranslation(template.description) : ''
    }));
  };

  const onChangeRichEdit = (editorState) => {
    updateStateByField('description', stateToHTML(editorState.getCurrentContent()));
  };
  const handleOnCreate = () => {
    setCreating(true);
    const data = {
      name: state.name,
      description: state.description
    };
    initChecklistAPI(state.idtemplate, data)
      .then((res) => {
        onCreate(res);
        setCreating(false);
        handleClose();
      })
      .catch((e) => {
        setCreating(false);
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(loadQuestinaireTemplates(true, false));
  }, []);

  if (creating) {
    return (
      <ModalCenter open>
        <Loader loaderAction={LoaderAction.Creating} />
      </ModalCenter>
    );
  }
  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {templates === null || catalog === null || creating ? (
          <Loader />
        ) : (
          <>
            <TextField
              variant="standard"
              value={state.name}
              onChange={(e) => updateStateByField('name', e.target.value)}
              label={formattedMessages.checklistTitle}
            />
            {location && <TextField variant="standard" value={location} label={formattedMessages.locationOfElement} />}
            <FormControl variant="standard">
              <InputLabel>{formattedMessages.questionnaireTemplate}</InputLabel>

              <Select variant="standard" value={state.idtemplate} onChange={onChangeQuestionnaireTemplate}>
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.versionId}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={cl.fieldContainerWithLabel}>
              <Typography color="textSecondary" variant="subtitle1">
                {formattedMessages.descriptionLabel}
              </Typography>
              <RichTextEditor key={`${state.idtemplate}`} text={state.description} onChange={onChangeRichEdit} />
            </div>
            {/*     <RichTextEditWithLanguageSwitch
            state={state.description}
            onChange={(_state) => updateStateByField('description', _state)}
          /> */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={state.idtemplate === -1} onClick={handleOnCreate} color="primary" variant="outlined">
          {formattedMessages.confirm}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {formattedMessages.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateChecklistDialog;
