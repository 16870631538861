import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { format as FNSFormat } from 'date-fns';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { fetchRA_Error, handleQuery, updateWorkerStatus } from '@zert-packages/actions/api';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import DeadlinePicker from '@zert-packages/components/shared/DeadlinePicker/DeadlinePicker';
import { useSnackbar } from 'notistack';
import AutoGrowInput from '@zert-packages/components/shared/AutoGrowInput/AutoGrowInput';
import { initProjectAPI } from '../SafetyInspectionPlugin/API';

const formattedMessages = {
  dialogTitle: <FormattedMessage id="newpage.project.createproject" defaultMessage="New project" />,
  confirm: <FormattedMessage id="newproject.create" defaultMessage="Create" />,
  cancel: <FormattedMessage id="newpage.project.cancel" defaultMessage="Cancel" />,
  projectTitle: <FormattedMessage id="newpage.project.projecttitle" defaultMessage="Project title" />,
  questionnaireTemplate: <FormattedMessage id="newpage.project.projecttemplate" defaultMessage="Project template" />,
  descriptionLabel: (
    <FormattedMessage id="newpage.project.additionaldesciption" defaultMessage="Additional description" />
  )
};

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: 20,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  fieldContainerWithLabel: {
    padding: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  }
});

function CreateNewProjectDialog({ handleClose, onCreate }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [templates, setProjectTemplates] = useState(null);
  const [creating, setCreating] = useState(false);
  const catalog = useSelector((state) => state.catalog);
  const [state, setState] = useState({
    idtemplate: -1,
    name: '',
    id: '',
    help: null,
    description: null,
    responsible: null,
    responsibleText: null,
    leader: null,
    manager: null,
    start: null,
    stop: null
  });

  const updateStateByField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const handleChangeCustomer = (user) => {
    if (user && user.name && (!state.responsible || user.name != state.responsible.name)) {
      updateStateByField('responsible', user);
      updateStateByField('responsibleText', null);
    }
    if (user && user != '' && !user.name && (!state.responsibleText || user != state.responsibleText)) {
      updateStateByField('responsible', null);
      updateStateByField('responsibleText', user);
    }
  };

  const onChangeTemplate = (e) => {
    const { value } = e.target;
    const todayDate = FNSFormat(new Date(), 'yyyy-MM-dd');
    const template = templates.find((template) => value === template.versionId);
    setState((prev) => ({
      ...prev,
      idtemplate: e.target.value,
      name: `${template.name} ${todayDate}`,
      description: template && template.description ? getTranslation(template.description) : ''
    }));
  };

  const handleOnCreate = () => {
    setCreating(true);
    const data = {
      name: state.name,
      description: state.description,
      projectId: state.id,
      start: state.start && state.start,
      stop: state.stop && state.stop,
      manager: state.manager && state.manager,
      leader: state.leader && state.leader,
      responsible: state.responsible && state.responsible,
      responsibleText: state.responsibleText
    };
    initProjectAPI(state.idtemplate, data)
      .then((result) => {
        return updateWorkerStatus(result, { snackbar });
      })
      .then((info) => {
        setCreating(false);
        handleClose();
        onCreate(info);
      })
      .catch((error) => {
        setCreating(false);
        handleClose();
        dispatch(fetchRA_Error(error));
      });
  };
  useEffect(() => {
    handleQuery('/project/getAllTemplates')
      .then((templates) => {
        setProjectTemplates(templates);
      })
      .catch((error) => {});
  }, []);

  if (creating) {
    return (
      <ModalCenter open>
        <Loader loaderAction={LoaderAction.Creating} />
      </ModalCenter>
    );
  }

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {templates === null || catalog === null || creating ? (
          <Loader />
        ) : (
          <>
            <div className="captionLabel">{formattedMessages.projectTitle}</div>
            <TextField
              variant="standard"
              value={state.name}
              onChange={(e) => updateStateByField('name', e.target.value)}
            />

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.projectid" defaultMessage="Project ID" />
            </div>
            <TextField variant="standard" value={state.id} onChange={(e) => updateStateByField('id', e.target.value)} />

            <div className="captionLabel">{formattedMessages.questionnaireTemplate}</div>
            <FormControl variant="standard">
              <Select variant="standard" value={state.idtemplate} onChange={onChangeTemplate}>
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.versionId}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.project.owner" defaultMessage="Project Owner" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <UserSuggestBox
                onChange={(e) => {
                  updateStateByField('leader', e);
                }}
                getUsers={getUsersQuery}
                getUserId={(user) => user.username}
                getUserLabel={(user) => user.name}
                initialValue={state.leader && state.leader.name}
              />
            </div>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.projectmanager" defaultMessage="Project Manager" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <UserSuggestBox
                onChange={(e) => {
                  updateStateByField('manager', e);
                }}
                getUsers={getUsersQuery}
                getUserId={(user) => user.username}
                getUserLabel={(user) => user.name}
              />
            </div>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.responsible" defaultMessage="Customer" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <UserSuggestBox
                onChange={(e) => {
                  handleChangeCustomer(e);
                }}
                getUsers={getUsersQuery}
                getUserId={(user) => user.username}
                getUserLabel={(user) => user.name}
              />
            </div>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.start" defaultMessage="Start date" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <DeadlinePicker deadline={state.start} onChangeCallback={(e) => updateStateByField('start', e)} />
            </div>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.stop" defaultMessage="Stop date" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <DeadlinePicker
                deadline={state.stop}
                onChangeCallback={(e) => {
                  updateStateByField('stop', e);
                }}
              />
            </div>

            <div className="captionLabel">
              <FormattedMessage id="newpage.project.additionaldesciption" defaultMessage="Additional Description" />
            </div>
            <div className="edit" style={{ background: 'none' }}>
              <AutoGrowInput
                defaultValue=""
                onChangeCallback={(e) => updateStateByField('description', e.target.value)}
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={state.idtemplate === -1} onClick={handleOnCreate} color="primary" variant="outlined">
          {formattedMessages.confirm}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {formattedMessages.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewProjectDialog;
